<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 인터뷰 요청 -->
				<div class="list-box">
					<fixTop :inContent="true" fixClass="top-fix">
						<template v-slot:conts>
							<div class="bt-list mt-10">
								<img src="/images/admin/list_shortcut.png" alt="목록가기"  @click="$router.go(-1)">
							</div>

							<span v-if="interviewReqList.length > 0">
								<div class="itv-result-box" v-for="row in interviewReqList" :key="row.projectSeq">
									<div class="prj_ct">
										<div class="mark-box">
											<div class="mark bp" v-if="row.projectStatus == '4'" >제안단계</div>
											<div class="mark pf" v-else-if="row.projectStatus == '1'">수 행 중</div>
											<div class="mark ep" v-else>수 행 예 정</div>

											<!-- <div v-if="row.recrStartYyyymmdd <= nowYyyymmdd && row.recrEndYyyymmdd >= nowYyyymmdd" class="mark rc">+모 집 중</div> -->
											<div v-if="row.recrEndDateDiff >= 0" class="mark rc">+모집중</div>
											<div class="regDate">등록일자<span style="margin-left: 10px;">{{row.regDate | date('yyyy. MM. dd')}}</span></div>
										</div>
										<div class="prj_outline">
											<div class="top">
												<div class="outline">
													<div class="label">프로젝트명</div>
													<div class="value prj prj-tit-hover" style="width: 750px;" @click="clickProjectInfo(row.projectSeq, row.projectStatus)">{{row.projectNm}}</div>
												</div>
												<div class="outline">
													<div class="label ivl03" style="margin-right: -3px;">개요</div>
													<div class="value text">{{row.projectDtlDesc}}</div>
												</div>
												
												<div class="outline">
													<div class="label ivl02">고 객 사</div>
													<div style="display: inline-flex; width: 750px;">
														<div class="prj_outline_clientname">
															{{row.clientNm}}
														</div>
														<div class="prj_outline_clientloc">
															<img src="/images/location.png" alt="위치" />{{row.projectLocCont}}
														</div>
													</div>
												</div>
												<div class="outline">
													<div class="label ivl05">산업분야</div>
													<div class="value" style="display: inline-block !important;">
														<SelectComp type="text" cdId="PRO105" :value="row.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="row.indtypeClassCd"/>
													</div>
												</div>
											</div>
											
											<div class="outline">
												<div class="label ivl02">수 행 사</div>
												<div class="value">{{row.corpNm}}</div>
											</div>
											<div class="outline">
												<div class="label">개 발 기 간</div>
												<div class="value">{{row.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{row.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
											</div>
											<div class="outline">
												<div class="label ivl05">직무분야</div>
												<div class="value">
													<ul class="duty-list">
														<li v-for="(duty, index) in row.dutyList" :key="index">
															<span>
																<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
															</span>
														</li>
													</ul>
												</div>
											</div>
											<div class="outline">
												<div class="label ivl05">개발기술</div>
												<div class="value text">{{row.devTechCont}}</div>
											</div> 
											
										</div>

										<!--고객CI/지원자정보-->
										<div class="client_info">
											<div class="ci_box">
												<!-- <ImageComp type="image" alt="고객사 로고"/> -->
												<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+row.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
											</div>
											<div class="deadline">
												<span v-if="row.recrEndDateDiff >= 0">
													<img class="period" src="/images/tec/prj/date.png" alt="마감기간"/> 마감 <span class="period">{{row.recrEndDateDiff}}일</span> 전
												</span>
												<img class="member" src="/images/tec/prj/member_sm.png" alt="합격인재"/> <span class="ivl06">합격 </span><span class="member">{{row.interview04Cnt}}명</span>
											</div>
										</div>	
										
									</div>

									<div style="padding: 20px;">
										<!-- <div class="filter itv">
											<div class="filter_box int">
												<ul>
													<li><img class="filter" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '1');">평가등급 높은 순</p><span>ㅣ</span></li>
													<li><img class="filter" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '2');">평가등급 낮은 순</p><span>ㅣ</span></li>
													<li><img class="filter" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '3');">기술등급 높은 순</p><span>ㅣ</span></li>
													<li><img class="filter" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '4');">기술등급 낮은 순</p></li>
												</ul>
											</div>
										</div> -->
										<div class="Board type3" style="margin-top: 0;">
											<table class="Board_type3 human itv">
												<colgroup>
													<col width="3%">
													<col width="10%">
													<col width="10%">
													<col width="7%">
													<col width="16%">
													<col width="8%">
													<col width="15%">
													<col width="12%">
													<col width="*">
												</colgroup>
												<thead>
													<tr>
														<th>NO</th>
														<th>지원분야</th>
														<th>인터뷰 결과</th>
														<th>평가등급</th>
														<th>회원명</th>
														<th>기술등급</th>
														<th>경력</th>
														<th>학력-졸업</th>
														<th>직무</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(intvRow, rIdx) in row.intvReqPersonList" :key="rIdx" @click="tecMberSeq = intvRow.mberSeq; seletedIndex = rIdx" :class="tecMberSeq == intvRow.mberSeq && seletedIndex == rIdx ? 'click' : '' ">
														<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rIdx + 1)}}</td>
														<!-- <td class="score">{{intvRow.rn}}</td> -->
														<td class="score">{{intvRow.workCont}}</td>
														<td class="score">
															<gradeImg type="INTERVIEW_RES" :grade="intvRow.statusImg" />
															<img class="change" src="/images/itv_change.png" alt="교체화살표" v-if="intvRow.rn == undefined && intvRow.statusImg == '00'"/>
															<p class="result ivl11" v-if="intvRow.statusImg == '00'">합격</p>
															<p class="result" v-if="intvRow.statusImg == '01'">불 합 격</p>
															<img class="change" src="/images/itv_change.png" alt="교체화살표" v-if="intvRow.statusImg == '02'"/>
															<p class="result change" v-if="intvRow.statusImg == '02'">교체투입</p>
															<p class="result in" v-if="intvRow.statusImg == '03'">인터뷰중</p>
														</td>
														<td class="score">
															<div class="gradeimg">
																<gradeImg type="TOT_SM_EVAL" :grade="intvRow.totEvalGrade" />
															</div>
														</td>
														<td class="score">{{intvRow.mberNm}} ({{intvRow.gender}} / {{intvRow.birthYr}}년생)</td>
														<td class="score">{{intvRow.techGradeNm}}</td>
														<td class="score">{{intvRow.totWorkMonthCnt | months}}</td>
														<td class="score"><SelectComp type="text" :value="intvRow.schoolDivCd" cdId="PRO121"/></td>
														<td class="score"><span class="duty">{{intvRow.duty}}</span></td>
													</tr>
												</tbody>
											</table>
											<!-- 페이징부분 -->
											<pagingComp :pageInfo="pageInfo" @page-click="goPage" :classNm="'mb-0'"/>
										</div>
									</div>
								</div>
							</span>

							<!-- 요청한 인터뷰 결과가 없을 경우 -->
							<div class="project_box type" v-else>
								<div class="prj_pf none">
									요청하신 인터뷰 결과가 없습니다!
								</div>
							</div>

							<!-- 페이징부분 -->
							<!-- <pagingComp :pageInfo="pageInfo" @page-click="goPage" /> -->
						</template>
					</fixTop>

					<!-- footer -->
					<!-- <footer-layout type="left"/> -->
				</div>

				<!-- 상세보기 -->
				<tec-profile :tecMberSeq="tecMberSeq" v-model="detailObj" @update="selectIntvReqList()" @profileClose="profileClose()"/>
			</div>
		</div>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from '@/components/highpro/GradeImg.vue';
import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			interviewReqList : [],
			pageInfo : {
				pageIndex : 1,
				pageUnit : 15,
				pageSize : 10
			},

			corpdiv : this.$store.state.userInfo.mberDivCd,
			
			srcFilter : {
				// pageIndex : '1',
				ordCol : '1',
				// srcTxt : this.$route.params.projectNm || '',
			},

			tecMberSeq : '',
			seletedIndex : 0,
			detailObj : {},

			nowYyyymmdd : new Date().format("yyyyMMdd"),
			
		}
	},

	components : {
		gradeImg, tecProfile, fixTop, pagingComp
	},

	beforeMount() {
		this.selectIntvReqList();
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		selectIntvReqList(div) {
			var param = this.srcFilter;
			
			param.corpdiv = this.corpdiv;
			param.projectSeq = this.$route.params.projectSeq != undefined ? this.$route.params.projectSeq : '';

			param.pageUnit = this.pageInfo.pageUnit;
			param.pageSize = this.pageInfo.pageSize;
			param.pageIndex = this.pageInfo.pageIndex;
			
			if(div) param.pageIndex = div;

			this.$.httpPost('/api/prj/prj/selectInterviewReqList', param)
				.then((res) => {
					// console.log('RESULT :', res);

					this.interviewReqList = res.data.interviewReqList;

					if(this.tecMberSeq) this.tecMberSeq = '';

					for(var i in this.interviewReqList){
						this.pageInfo = res.data.interviewReqList[i].pageInfo;
						if(this.interviewReqList[i].dutyFieldCont){
							var tempArr = this.interviewReqList[i].dutyFieldCont.split(',');
							this.interviewReqList[i].dutyList = [];

							for(var j in tempArr){
								this.interviewReqList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																   dutyFieldCd  : tempArr[j]});
							}
						}

					}
				})
		},

		orderSort(div) {
			this.srcFilter.ordCol = div;
			this.selectIntvReqList();
		},

		// 페이지 이동
		goPage(page) {
			this.pageInfo.pageIndex = page;
			this.selectIntvReqList();
		},

		// 기술인재 상세 닫기
		profileClose() {
			this.tecMberSeq = '';
			this.detailObj = {};
		},

		// 프로젝트 상세보기 팝업
		clickProjectInfo(projectSeq, projectRecrStatus) {
			var param = {
				projectSeq : projectSeq,
				projectRecrStatus : projectRecrStatus
			};
			this.$.popup('/dco/pmg/prj/PRJ305P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},

	}
}
</script>